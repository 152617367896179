body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 400px) {
  .adjustButtonMargin {
    margin-left: 2em;
  }
}
@media only screen and (min-width: 1000px) {
  .burgerMenuItems {
    margin-left: 2em;
  }
}
@media only screen and (max-width: 1000px) {
  .burgerMenuItems {
    margin-top: 0.5em;
  }
}

@media only screen and (max-width: 400px) {
  .adjustButtonMargin {
    margin-top: 1em;
  }
}
